<script>
  import ListItem from "./ListItem.vue";

  export default {
    props: ["showName", "desktop", "menus"],
    components: {
      ListItem,
    },
    data() {
      return {
        navegationList: null,
        moreViewsDesktop: false,
        availableSpace: 0,
      };
    },
    computed: {
      menusWithActive() {
        const route = this.$route;
        const { meta } = route;

        this.handleScreenResize();

        return this.menus.map((menu) => {
          menu.isActive = menu.id === meta?.menuActive?.id;
          return menu;
        });
      },
      getItemsCanShow() {
        if (this.moreViewsDesktop && !this.desktop) {
          return this.menus.length;
        }

        const minItems = 2;
        const maxItems = 6;
        const calcEle = Math.floor(this.availableSpace / 100);
        const isMinItems = minItems > calcEle;
        const isMaxItems = maxItems < calcEle;

        if (isMinItems) {
          return minItems;
        }

        return isMaxItems ? maxItems : calcEle;
      },
      itemsByTypeShow() {
        const menusClone = structuredClone(this.menusWithActive);
        const menusShow = this.moveActiveMenu(menusClone, this.getItemsCanShow);
        const menusHidden = menusShow.splice(this.getItemsCanShow);

        this.verifyRequireShowMore({ menusShow, menusHidden });

        return { menusShow, menusHidden };
      },
      screenWidth() {
        return this.$store.state.screen.width;
      },
    },
    watch: {
      $route() {
        this.closeModalViewOptions();
      },
    },
    methods: {
      verifyRequireShowMore({ menusShow = [], menusHidden = [] }) {
        const menusHiddenLength = menusHidden.length;
        const requireConcat = menusHiddenLength === 1;

        if (!menusHiddenLength || !menusShow.length) {
          return;
        }
        if (requireConcat) {
          menusShow.push(menusHidden[0]);
          return;
        }

        menusShow.push({ name: "Ver más", to: "mas", icon: "plusOptions" });
      },
      moveActiveMenu(menus, limitShow) {
        const finIdx = menus.findIndex(({ isActive }) => isActive);

        if (finIdx === -1) {
          return menus;
        }
        if (finIdx + 1 > limitShow) {
          const [menuSelected] = menus.splice(finIdx, 1);
          menus.splice(limitShow - 1, 0, menuSelected);
        }

        return menus;
      },
      openOptions() {
        this.moreViewsDesktop = !this.moreViewsDesktop;
      },
      closeModalViewOptions() {
        this.moreViewsDesktop = false;
      },
      handleScreenResize() {
        const widthNavegationList = this.$refs?.navegationList?.offsetWidth || 60;
        this.availableSpace = widthNavegationList - 60 || 0;

        this.closeModalViewOptions();
      },
    },
    mounted() {
      this.$nextTick(() => {
        this.handleScreenResize();
        setTimeout(this.handleScreenResize, 600);
      });
      window.addEventListener("resize", this.handleScreenResize);
    },
    beforeDestroy() {
      window.removeEventListener("resize", this.handleScreenResize);
    },
  };
</script>

<template>
  <nav class="navegation">
    <div v-if="moreViewsDesktop" class="navegation__overlay" @click="closeModalViewOptions"></div>
    <ul ref="navegationList" class="navegation__list">
      <ListItem v-for="item in itemsByTypeShow.menusShow" :key="item.name" :item="item" :showName="showName" @toggle="openOptions" />
    </ul>
    <ul class="navegation__listMore" :class="{ showModalOptions: moreViewsDesktop }" v-if="screenWidth >= 768">
      <ListItem v-for="item in itemsByTypeShow.menusHidden" :key="item.name" :item="item" :showName="showName" @toggle="openOptions" />
    </ul>
  </nav>
</template>

<style lang="scss">
  .navegation {
    position: fixed;
    bottom: 10px;
    right: 10px;
    left: 10px;
    z-index: 101;
    padding: 10px;
    border-radius: 7px;
    box-shadow: 0 0 7px 0 rgba(48, 48, 48, 0.25);
    background: $white;
    &__overlay {
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: -1;
      background: transparent;
    }
    &__list {
      position: relative;
      @include Flex(row);
      gap: 10px;
      width: 100%;
      flex-wrap: wrap;
      cursor: pointer;
      background: $white;
    }
    &__listMore {
      @include Flex();
      position: absolute;
      top: -100%;
      z-index: 1000;
      opacity: 0;
      padding: 10px;
      border-radius: 10px;
      background: $white;
      box-shadow: 0px 4px 12px 2px #e2e2e2;
      transition: top 0.4s ease-in-out, opacity 0.4s ease-in-out;
    }
    @media (min-width: 768px) {
      position: static;
      @include Flex(row, center, flex-start);
      width: 100%;
      padding: 0;
      box-shadow: none;
      background: none;
      &__list {
        @include Flex();
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
      }
    }
  }
  .showModalOptions {
    bottom: 0;
    opacity: 1;
    @media (min-width: 768px) {
      margin-left: 80px;
      bottom: auto;
      top: 68px;
    }
    @media (max-width: 768px) {
      margin-left: 75px;
    }
  }
</style>
