<template>
  <div class="page-admin" v-bind="getAllData">
    <div class="page-admin-container" v-if="locale">
      <div class="page-admin-header" sticky noprint>
        <div class="page-admin-header-content">
          <div class="page-admin-header-left">
            <div class="page-admin-header-search">
              <div class="page-admin-title">
                <h1>{{ seccion }}</h1>
              </div>
              <AdminSearch
                :locale="locale"
                :search="search"
                :searching="searching"
                :leaveSearch="leaveSearch"
                :autoSearch="autoSearch"
                :enterSearch="enterSearch"
                :searchTotal="searchTotal"
                :searchPage="searchPage"
                @clickResult="clickSearchResult"
                class="search" />
            </div>
          </div>
          <Header :brand="brand" :menus="menu">
            <div class="page-admin-header-user" v-if="user">
              <div class="page-admin-header-user-button --apps" @click="() => changeShowMenuApps(true)">
                <iconic name="apps" />
              </div>
              <router-link class="page-admin-header-user-button" to="/admin/log/?type=allActivity" v-if="user.roles.includes('super')">
                <i class="fa fa-history"></i>
              </router-link>
              <div class="avatar" @click="$refs.myUserMenu.toggleMenu">
                <figure :class="[!user.picture ? '' : 'border']" :style="`background-image: url(${user.picture});`">
                  <span v-if="!user.picture">{{ user.name.charAt(0) }}</span>
                </figure>
              </div>
            </div>
          </Header>
          <Navbar :menus="menu" v-if="widthViewport" :showName="true" />
        </div>
      </div>
      <div class="page-admin-container" :class="{ loading: loading }">
        <div class="page-admin-container-main" docprint>
          <router-view :data="this.data" :user="user"></router-view>
        </div>
      </div>
    </div>
    <UserMenu :user="user" ref="myUserMenu" noprint></UserMenu>
    <ModelsRecord ref="myRecord" noprint></ModelsRecord>
    <TemplateModalApps :isVisible="showMenuApps" :closeM="() => changeShowMenuApps(false)" />
  </div>
</template>

<script>
  import api from "../../api";
  import store from "../../store/index.js";
  import UserMenu from "@/common/userMenu";
  import ModelsRecord from "./ModelsRecord";
  import AdminSearch from "./AdminSearch";
  import SocketSessions from "@/common/socket/socketSessionsInstance";
  import updSession from "@/common/UpdSession";
  import Header from "../../common/Header/Header.vue";
  import menuList from "../../app/menuList";
  import Navbar from "../../common/Header/Navbar/Navbar.vue";
  import TemplateModalApps from "@/common/Header/RenderMenuApps.vue";

  export default {
    components: { UserMenu, ModelsRecord, AdminSearch, Header, Navbar, TemplateModalApps },
    metaInfo: {
      title: "Models1A - Admin panel",
    },
    data() {
      return {
        loading: null,
        locale: null,
        data: null,
        search: null,
        searchQuery: null,
        searching: false,
        searchTotal: 0,
        searchPage: 1,
        socketSessionInstance: null,
        showMenuApps: false,
      };
    },
    computed: {
      getAllData: async function () {
        await this.getdataLang();
      },
      user() {
        return this.$store.state.userProfile;
      },
      menu() {
        return menuList.menu;
      },
      brand() {
        return menuList.brand;
      },
      seccion() {
        const { filter } = this.$route.query;

        const objetRoutes = {
          all: "Estadísticas",
          new: "Nuevos",
          selected: "Seleccionadas",
          admitted: "Admitidas",
          hired: "Contratadas",
          approved: "Aprobados",
          noassistence: "No asistió",
          pending: "Pendientes",
          disapproved: "No Aprobados",
          noselected: "No seleccionadas",
          waiting: "En espera",
          noadmitted: "No admitidas",
          deserter: "Desertoras",
          waiver: "Renuncias",
          endContract: "Cancelación de contrato",
          users: "Usuarios",
        };

        return objetRoutes[filter] || "";
      },
      widthViewport() {
        return this.$store.state.screen.width < 768;
      },
    },
    methods: {
      changeShowMenuApps(newStatus) {
        this.showMenuApps = newStatus;
      },
      getStatistics: async function () {
        this.loading = true;
        let myRoute = this.$route.fullPath.split("/?");
        const _host = `${process.env.VUE_APP_API_HOST}admin/?${myRoute[1]}`;
        let response = await api.get(_host);
        this.data = response.data;
        this.$root.$emit("newStatistics", this.data);
        this.loading = false;
      },
      getdataLang: async function () {
        this.locale = await store.getters["localeLang"];
      },
      filterInfo: function (info) {
        this.$root.$emit("filterInfo", info);
      },
      enterSearch: function () {
        const mysearch = document.querySelector(".page-admin-header");
        const mbody = document.querySelector("body");
        mysearch.classList.add("searching");
        mbody.classList.add("onmodal");
      },
      leaveSearch: function () {
        const mysearch = document.querySelector(".page-admin-header");
        const mbody = document.querySelector("body");
        mysearch.classList.remove("searching");
        mbody.classList.remove("onmodal");
      },
      autoSearch: async function (options = {}) {
        this.searching = true;
        let [host, page] = [null, Number(options.page) || 1];

        if (event.srcElement.value) {
          this.searchPage = 1;
          this.searchQuery = event.srcElement.value;
        } else {
          this.searchPage = page;
        }

        const result = await api.get(`${process.env.VUE_APP_API_HOST}admin/models/search?q=${this.searchQuery}&page=${this.searchPage}`);

        if (Number(page) === 1) {
          this.search = result.data.models;
          this.searchTotal = result.data.total;
        } else {
          this.search.push(...result.data.models);
        }

        this.searching = false;
      },
      clickSearchResult: function (model) {
        this.$refs.myRecord.modelView({ action: "openload", id: model._id });
        this.leaveSearch();
      },
      async initilizeSocketSessions() {
        const socket = new SocketSessions();
        this.socketSessionInstance = await socket.connectToSessionsManager(this.user);

        if (this.socketSessionInstance.connected) {
          this.socketSessionInstance.on("verifyConnection", () => {
            this.socketSessionInstance.emit("isAvailable");
          });
          this.socketSessionInstance.on("expiredSession", () => {
            this.$store.dispatch("user/logoutClient");
            window.location.href = "/sesionwrong";
          });
        }
      },
    },
    async mounted() {
      updSession();
      try {
        await this.getStatistics();
      } catch (error) {
        console.log(error);
      }
      this.$root.$on("updateStatics", async (data) => {
        await this.getStatistics();
      });
      if (!this.socketSessionInstance) {
        await this.initilizeSocketSessions();
      }
    },
  };
</script>

<style lang="scss">
  @import "@/assets/styles/vars.scss";
  .page-admin {
    padding-bottom: 80px;
    &-title {
      margin-top: 5px;
      color: $primary-color;
      font-size: 20px;
      font-family: $newFont;
    }
    &-header {
      background-color: $white;
      box-shadow: 0 0 $mpadding/2 $alto;
      display: flex;
      top: 0;
      height: $header_height;
      align-items: center;
      z-index: 99;
      &-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }
      &-left {
        display: flex;
        align-items: center;
        .title-label {
          display: none;
        }
        &-title {
          font-size: 100%;
          line-height: $mpadding;
          white-space: nowrap;
        }
      }
      &-search {
        position: absolute;
        top: 65px;
        width: 100%;
        border-bottom: 1px solid #e9e9e9;
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        background: #fff;
        .search {
          width: 350px;
        }
        .form {
          position: relative;
          border-radius: $mradius/2;
          background: #f1f3f4;
          &::before {
            font-family: fontawesome;
            content: "\f002";
            position: absolute;
            left: 10px;
            top: 7px;
            pointer-events: none;
          }
          .results {
            padding: $mpadding;
            position: absolute;
            top: calc(100% + #{$mpadding/2});
            width: 100%;
            background: $white;
            box-shadow: $dshadow;
            border-radius: $mradius/2;
            display: none;
            overflow: hidden;
            max-height: 50vh;
            overflow-y: auto;
            z-index: 5;
            .item {
              cursor: pointer;
              &:not(:first-child) {
                margin-top: $mpadding/2;
                padding-top: $mpadding/2;
                border-top: solid 1px $alto;
              }
            }
          }
        }
        input[type="search"] {
          position: relative;
          padding: $mpadding/2;
          appearance: none;
          font-size: 100%;
          width: 100%;
          border: none;
          background: transparent;
          padding-left: 40px;
          z-index: 2;
        }
      }
      &-user {
        display: flex;
        align-items: center;
        border-left: 1px solid #d5d5d5;
        height: 40px;
        margin: 9px 0;
        .avatar {
          display: table;
          figure {
            width: 30px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            background: $primary_color;
            color: white;
            border-radius: 50%;
            margin: 10px;
            cursor: pointer;
            user-select: none;
            background-size: cover;
            box-shadow: $dshadow;
          }
          &:active {
            opacity: 0.8;
          }
          .border {
            border: 1px solid $primary_color;
          }
        }
        .name {
          display: none;
        }
        &-button {
          padding: 0 $mpadding/3;
          color: $primary_color;
          cursor: pointer;
          height: $header_height;
          display: flex;
          align-items: center;
          .iconic {
            font-size: 120%;
          }
          &:active {
            opacity: 0.8;
          }
        }
      }
      .button {
        height: 100%;
      }
      &.searching {
        .title,
        .consult-btn {
          display: none;
        }
        .results {
          display: block;
        }
        .overlay {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 2;
        }
      }
    }
    &-container {
      display: block;
      &-main {
        width: 100%;
      }
      &.loading::after,
      &[loading]::after {
        position: fixed;
      }
    }
    .button {
      position: relative;
      padding: $mpadding;
      display: inline-block;
      &.router-link-parent-active {
        background-color: #f7f7f7;
      }
      i:first-child {
        font-size: 120%;
        margin-right: 10px;
      }
      &.consult-btn {
        i {
          margin-right: 0;
        }
        .txt-label {
          display: none;
        }
      }
    }
    *[record] {
      &[gender="male"] {
        background-color: $gender_male_bg;
        .window-modal-header {
          background-color: $gender_male_bg;
        }
        &:hover {
          background-color: $gender_male_bg;
        }
        [role="option"][priority] {
          pointer-events: none;
          opacity: 0.5;
        }
        [role="option"][satelite] {
          pointer-events: none;
          opacity: 0.5;
        }
      }
      &[priority="high"]:not([gender="male"]) {
        background-color: $priority_high_bg;
        .window-modal-header {
          background-color: $priority_high_bg;
        }
        &:hover {
          background-color: $priority_high_bg;
        }
        [role="option"][gender] {
          pointer-events: none;
          opacity: 0.5;
        }
        [role="option"][satelite] {
          pointer-events: none;
          opacity: 0.5;
        }
      }
      &[satelite="satelite"] {
        background-color: $satelite_bg;
        .window-modal-header {
          background-color: $satelite_bg;
        }
        &:hover {
          background-color: $satelite_bg;
        }
        [role="option"][gender] {
          pointer-events: none;
          opacity: 0.5;
        }
        [role="option"][priority] {
          pointer-events: none;
          opacity: 0.5;
        }
      }
    }
    .fa-history:before {
      font-size: 24px;
    }
    @media (min-width: $tablet_width) {
      padding-bottom: 0;
      &-header {
        &-left {
          &-title {
            font-size: 130%;
          }
          .title-label {
            display: inline-block;
          }
        }
        &-user {
          .avatar {
            figure {
              width: 38px;
              height: 38px;
              line-height: 38px;
              margin: 5px 10px;
            }
          }
          .name {
            display: block;
          }
          &-button {
            padding: 0 $mpadding;
          }
        }
        &.searching {
          .title,
          .consult-btn {
            display: inline-block;
          }
        }
      }
      &-container {
        &-main {
          display: inline-block;
          width: 100%;
          padding: 20px;
          margin-top: 64px;
        }
      }
      .button {
        &.consult-btn {
          i {
            margin-right: 10px;
          }
          .txt-label {
            display: inline-block;
          }
        }
      }
    }
    @media (max-width: 767px) {
      .page-admin-header-user {
        position: absolute;
        right: 16px;
      }
      .page-admin-home-statistics {
        margin-top: 80px;
      }
    }
  }
</style>
