<script>
  export default {
    props: ["to"],
    methods: {
      navigateTo() {
        this.$router.push(this.to).catch(() => {});
      },
    },
  };
</script>

<template>
  <section @click="navigateTo" class="headerBrand">
    <img class="headerBrand__icon" src="../assets/img/MySpaceBrand.svg" />
  </section>
</template>

<style lang="scss">
  .headerBrand {
    @include Flex(row);
    position: relative;
    height: 65px;
    width: 120px;
    overflow: hidden;
    &__icon {
      width: 100%;
      margin-top: 5px;
      object-fit: contain;
      object-position: center;
    }
  }
</style>
