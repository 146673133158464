<script>
  import Navbar from "./Navbar/Navbar.vue";
  import HeaderBrand from "./HeaderBrand.vue";

  export default {
    components: {
      Navbar,
      HeaderBrand,
    },
    props: ["brand", "menus"],
    data() {
      return {
        navbar: null,
      };
    },
    computed: {
      screenWidth() {
        return this.$store.state.screen.width >= 768;
      },
      firstRoute() {
        return this.menus[0]?.to;
      },
    },
  };
</script>

<template>
  <header class="newHeader">
    <HeaderBrand :brand="brand" :to="firstRoute" />
    <Navbar :menus="menus" ref="navbar" v-if="screenWidth" :desktop="screenWidth" :showName="true" />
    <slot></slot>
  </header>
</template>

<style lang="scss">
  .newHeader {
    position: fixed;
    top: 0;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    align-content: center;
    justify-items: stretch;
    height: 65px;
    width: 100vw;
    padding: 0px 15px;
    background: $white;
    box-shadow: 0 0 7px 0 rgba(48, 48, 48, 0.25);
    transition: transform 1s ease;
    z-index: 20;
    @media (min-width: 768px) {
      grid-template-columns: auto 1fr auto;
    }
  }
</style>
