<script>
  import { getHost } from "@/common/app.main";
  import AppsAvailable from "../../AppsAvailable";
  import { mapActions, mapGetters } from "vuex";

  export default {
    components: {
      AppsAvailable,
    },
    computed: {
      ...mapGetters("user", { getProfile: "getProfile" }),
      isMainApp(){
        return process.env.VUE_APP_KEY_NAME === "autologin";
      },
      showWiki(){
        return this.isMainApp && this.getProfile && this.getProfile.role !== 'model'
      },
    },
    methods: {
      ...mapActions("applications", ["setShowApps"]),
      goToStart() {
        window.scrollTo(0, 0);
        const baseAppUrl = getHost();
        const routePath = "/apps";

        if (this.isMainApp) {
          this.$router.push(routePath);
          this.setShowApps();
          return;
        }

        const sstk = this.$user.SSID;
        const authLink = `${baseAppUrl}/auth/?sstk=${sstk}&redirect=${routePath}`;
        this.setShowApps();
        location.href = authLink;
      },
      openWikiInNewTab() {
        window.open("https://wiki.models1a.com", '_blank', 'noopener');
      },
    },
  };
</script>

<template>
  <section class="availableApps">
    <div class="availableApps__go">
      <div :class="['availableApps__container', !showWiki && 'availableApps__container--alone']" @click="goToStart">
        <iconic name="home" class="availableApps__icon" />
        <p class="availableApps__text">Home</p>
      </div>
      <div class="availableApps__container" @click="openWikiInNewTab" v-if="showWiki">
        <iconic name="supportIcon" class="availableApps__icon" />
        <p class="availableApps__text">Wiki</p>
      </div>
    </div>
    <div class="availableApps__line"></div>
    <AppsAvailable />
  </section>
</template>

<style lang="scss">
  .availableApps {
    @include Flex(column);
    padding: 15px;
    min-width: 320px;
    min-height: 270px;
    border-radius: 15px;
    background: $white;
    box-shadow: 0 3px 10px 0 rgba(58, 58, 58, 0.25);
    gap: 1px;
    &__go {
      @include Flex(row, space-between);
      width: 100%;
      font-size: 16px;
      font-weight: bold;
    }
    &__line {
      height: 1px;
      background: rgba(222, 222, 222, 1);
      width: 100%;
    }
    &__container {
      cursor: pointer;      
      @include Flex(row);
      width: 100%;
      gap: 15px;
      padding: 10px;
      border-radius: 10px;
      transition: background-color 0.3s ease;
      &--alone {
        justify-content: flex-start;
        .availableApps__icon{
          color: $primary-color;
        }
      }
      &:hover {
        background: rgba(189, 9, 9, 0.1);
        color: $primary-color;
      }
    }
    &__icon {
      font-size: 20px;
    }
    &__text {
      font-family: $newFont_semiBold;
    }
  }
</style>
