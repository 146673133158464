<script>
  import { getApps, goToApp } from "@/common/app.main.js";
  export default {
    components: {
      App: () => import("./App.vue"),
    },
    computed: {
      apps() {
        const currentApp = process.env.VUE_APP_KEY_NAME;
        const apps = getApps();
        return apps.filter((app) => {
          const notSameApp = app.key !== currentApp;
          const hasPermisionApp = this.allowApp(app);

          return notSameApp && hasPermisionApp;
        });
      },
    },
    methods: {
      async goToOtherApp(app) {
        const tokenName = process.env.VUE_APP_TOKEN_NAME;
        const appName = process.env.VUE_APP_KEY_NAME;
        const isDesktopApp = this.$store.state.isDesktopApp;

        await goToApp(app, this.$user, tokenName, appName, isDesktopApp);
      },
      allowApp(app) {
        if (!this.$user || !this.$user.apps) {
          return false;
        }

        return this.$user.apps.includes("all") || this.$user.apps.includes(app.key);
      },
    },
  };
</script>

<template>
  <div class="appsContainer">
    <App v-for="app in apps" :key="app.key" :app="app" :showname="true" @click.native="goToOtherApp(app)" />
  </div>
</template>

<style lang="scss">
  .appsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(95px, 1fr));
    justify-items: center;
    width: 345px;
    padding: 16px;
  }
</style>
