<template>
  <div class="user-menu" v-if="$user">
    <div class="user-menu-bgwrap" @click="toggleMenu"></div>
    <div class="user-menu-content">
      <div class="user-menu-card">
        <div class="user-menu-card-avatar" fcenter>
          <Avatar :class="['user-menu__avatar', isPicture($user) ? 'borderImageMedium' : '']" :user="$user" />
        </div>
        <div class="user-menu-card-header">
          <div class="user-menu-card-header-name">
            <p class="user-menu-card-header-name-username">{{ $user.role === "model" ? $user.artisticName : $user.user }}</p>
          </div>
          <div class="user-menu-card-header-role">
            <p class="user-menu-card-header-role-title">{{ dicUserRoles[$user.role] }}</p>
          </div>
          <div v-if="$user.role !== 'model'" class="user-menu-card-header-email">
            <iconic name="email" class="email-icon" />
            <p class="user-menu-card-header-email-title">{{ $user.email }}</p>
          </div>
        </div>
        <div class="user-menu-card-buttons">
          <div class="user-menu-card-buttons-button" @click="profile">
            <iconic name="userInput" />
            <p class="user-menu-card-buttons-button-title">Ver perfil</p>
          </div>
          <div class="user-menu-card-buttons-button" v-if="validateShowIpSecure" @click="openLocations">
            <iconic name="sede" />
            <p class="user-menu-card-buttons-button-title">Abrir sedes</p>
          </div>
        </div>
        <div class="user-menu-card-divider"></div>
        <div class="user-menu-card-option">
          <div @click="closeApp" class="user-menu-card-option-button"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { getMain, getHost } from "@/common/app.main.js";
  import Swal from "sweetalert2";
  import AxiosConnect from "./api/AxiosConnect";

  export default {
    components: {
      Avatar: () => import("./Avatar.vue"),
    },
    props: {
      user: Object,
    },
    data() {
      return {
        dicUserRoles: {
          employee: "empleado",
          superadmin: "superadmin",
          coordinator: "coordinador",
          monitor: "monitor",
          model: "modelo",
        },
      };
    },
    computed: {
      validateSite() {
        return process.env.VUE_APP_KEY_NAME === "autologin";
      },
      validateShowIpSecure() {
        const ipsecure = this.$user.rolesApp?.ipsecure;
        return ipsecure?.length && this.validateSite;
      },
    },
    methods: {
      toggleMenu: function () {
        const element = document.querySelector(".user-menu");
        element.classList.toggle("active");
      },
      isPicture: function (user) {
        return user && user.picture;
      },
      openLocations: async function () {
        try {
          const dataInstance = { baseURL: process.env.VUE_APP_IPSECURE_API, tokenName: process.env.VUE_APP_TOKEN_NAME };
          const { status } = await AxiosConnect.instance(dataInstance).get("command");

          const result = {
            isSuccess: status === 200,
            message: status === 200 ? "Se desbloquea acceso a todas las sedes." : "Error desbloqueando sedes.",
          };
          this.openSuccess(result);
        } catch (error) {
          console.error("error", error);
        }
      },
      profile() {
        this.toggleMenu();

        const baseAppUrl = getHost();
        const routePath = "/account";

        if (this.validateSite) {
          this.$router.push(routePath);
          return;
        }

        const sstk = this.$user.SSID;
        const authLink = `${baseAppUrl}/auth/?sstk=${sstk}&redirect=${routePath}`;

        location.href = authLink;
      },
      closeApp() {
        const baseAppUrl = getHost();
        this.toggleMenu();

        if (process.env.VUE_APP_KEY_NAME !== getMain().key) {
          localStorage.removeItem(process.env.VUE_APP_TOKEN_NAME);
        }

        location.href = `${baseAppUrl}/logout/?user=${this.$user._id}&sstk=${this.$user.SSID}`;
      },
      openSuccess: function (result, callback) {
        const alert = Swal.fire({
          icon: result.isSuccess ? "success" : "error",
          text: result.message,
          confirmButtonColor: "#bd0909",
        });
        alert.then((res) => {
          if (callback) callback();
        });
      },
    },
  };
</script>

<style lang="scss">
  .user-menu {
    display: none;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    &__avatar {
      width: 76px;
      height: 76px;
      margin: $space-16 $mpadding $mpadding/2 $mpadding;
      font-size: 200%;
    }
    &-card {
      position: relative;
      z-index: 100;
      background: linear-gradient(to bottom, #f5f5f6 20%, $white 20%);
      margin: $space-16;
      margin-top: 70px;
      width: 310px;
      border-radius: $radius-12;
      text-align: center;
      float: right;
      box-shadow: $space-0 $space-2 $space-12 #00000036;
      max-height: calc(100vh - 100px);
      overflow-y: hidden;
      &-avatar {
        text-align: center;
        display: flex;
        justify-content: center;
      }
      &-header {
        margin-top: $space-12;
        &-name {
          &-username {
            text-transform: capitalize;
            font-family: $newFont_semiBold;
            line-height: normal;
            color: #272727;
            font-size: 18px;
          }
        }
        &-role {
          &-title {
            text-transform: capitalize;
            font-family: $newFont_regular;
            line-height: normal;
            color: #272727;
            font-size: 13px;
          }
        }
        &-email {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          margin-top: $space-16;
          &-title {
            width: 70%;
            font-family: $newFont_regular;
            line-height: normal;
            color: #272727;
            font-size: 14px;
            margin-left: $space-6;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .email-icon {
            color: #bd0909;
            margin-top: 2px;
          }
        }
      }
      &-buttons {
        display: flex;
        justify-content: center;
        padding: $space-2 $space-16;
        margin: $space-12 auto;

        &-button {
          display: flex;
          width: 150px;
          align-items: center;
          border: 1px solid $primary_color;
          padding: 5px;
          color: #bd0909;
          font-size: 15px;
          border-radius: $radius-80;
          cursor: pointer;
          justify-content: center;
          margin: 0 $space-4;
          text-align: center;
          transition: all ease-in-out 0.3s;
          &:hover {
            background: rgba(189, 9, 9, 0.1);
            transition: all ease-in-out 0.3s;
          }
          &-title {
            font-family: $newFont_regular;
            line-height: normal;
            color: #bd0909;
            margin-left: $space-6;
          }
        }
      }
      &-divider {
        width: 80%;
        margin: 15px auto;
        border-bottom: solid 1px #dedede;
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
      }
      &-option {
        display: flex;
        justify-content: center;
        margin: $space-16 0;
        &-button {
          display: flex;
          align-items: center;
          cursor: pointer;
          padding: $space-6 $space-20;
          border-radius: $radius-80;
          background-image: url("../../src/assets/img/logOut.svg");
          background-size: 18px;
          background-repeat: no-repeat;
          background-position: 18px 50%;
          transition: all ease-in-out 0.3s;
          &:before {
            content: "Cerrar sesión";
            font-family: $newFont_regular;
            color: #272727;
            margin-left: $space-24;
            transition: all ease-in-out 0.3s;
          }
          &:hover {
            background-image: url("../../src/assets/img/logOutHoverRed.svg");
            background-position: 18px 50%;
            background-color: rgba(189, 9, 9, 0.1);
            transition: all ease-in-out 0.3s;
            &:before {
              color: #bd0909;
              transition: all ease-in-out 0.3s;
            }
          }
        }
      }
    }
    &.active {
      display: block;
    }
    &-bgwrap {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 0;
    }
  }
</style>
