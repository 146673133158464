import sessionsApi from "./api/sessionsAxios";

const timeInterval = 10 * 60 * 1000;

const updateSession = async () => {
  const token = localStorage.getItem(process.env.VUE_APP_TOKEN_NAME);
  if (token) {
    try {
      await sessionsApi.get(`/session/validsession`);
    } catch (error) {
      console.log("Error al actualizar la sesión", error);
    }
  }
};

const intervalUpdateSession = () => {
  setInterval(updateSession, timeInterval);
};

export default intervalUpdateSession;
