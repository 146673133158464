<script>
  export default {
    props: ["item", "showName"],
    computed: {
      showNew() {
        return this.item?.state === "New";
      },
    },
    methods: {
      openOptions() {
        this.$emit("toggle", this.item);
      },
      execMethod() {
        if (this.item.to === "mas") {
          return this.openOptions();
        }

        this.$router.push(this.item.to);
      },
    },
  };
</script>

<template>
  <div :class="['itemMenu', item.isActive && 'itemMenu--active']" @click="execMethod">
    <iconic :name="item.icon" class="itemMenu__icon"></iconic>
    <span v-if="showName" class="itemMenu__name">{{ item.name }}</span>
    <div class="itemMenu__new" v-if="showNew"><span class="itemMenu__new--text">NEW</span></div>
  </div>
</template>

<style lang="scss">
  .itemMenu {
    @include Flex(column);
    min-width: 80px;
    height: 45px;
    font-family: $newFont_regular;
    border-radius: 8px;
    position: relative;
    &:hover {
      font-weight: bold;
      color: $primary-color;
      background: rgba(189, 9, 9, 0.1);
      .itemMenu__icon {
        color: $primary-color;
      }
      .itemMenu__name {
        color: $primary-color;
      }
    }
    &--active {
      font-weight: bold;
      color: $primary-color;
      background: rgba(189, 9, 9, 0.1);
      .itemMenu__icon {
        color: $primary-color;
      }
      .itemMenu__name {
        color: $primary-color;
      }
    }
    &__name {
      font-size: 10px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: #000;
    }
    &__icon {
      font-size: 20px;
      color: rgba(52, 52, 52, 1);
    }
    &__new {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 31px;
      height: 14px;
      gap: 10px;
      border-radius: 2px;
      background: #000;
      box-shadow: -1px 1px 0.5px 0px rgba(0, 0, 0, 0.25);
      position: absolute;
      top: 4px;
      right: -12px;
      &--text {
        color: #fff;
        font-family: Montserrat;
        font-size: 9px;
        font-weight: 700;
      }
    }
    @media (min-width: 768px) {
      height: 50px;
      border-radius: 12px;
    }
  }
</style>
