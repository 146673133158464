import socket from "./socketClass.js";

class SocketSessions extends socket {
  /**
   * Metodo encargado de realizar una conexion del socket directamente al de sesiones
   * @param {Object} user
   * @returns
   */
  async connectToSessionsManager(user) {
    const tokenName = process.env.VUE_APP_TOKEN_NAME;
    const token = localStorage.getItem(tokenName);
    const { _id = "", SSID = "" } = user;
    const serverUrl = process.env.VUE_APP_SESSION_API.split("api/v")[0];
    const sessionSocket = {
      path: "/api/v1/socket",
      auth: { [tokenName]: token },
      serverUrl,
      query: `_id=${_id}&SSID=${SSID}`,
    };
    await this.connect(sessionSocket);
    if (this.socket.connected) {
      return this.socket;
    }
  }
}

export default SocketSessions;
