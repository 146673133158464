<script>
  import TemplateModalApps from "./ModalCardsViews/TemplateModalCard.vue";
  import ShowAppsModal from "./ModalCardsViews/Apps.vue";
  export default {
    components: { TemplateModalApps, ShowAppsModal },
    props: ["isVisible", "closeM"],
  };
</script>

<template>
  <TemplateModalApps :isVisible="isVisible" @closeM="closeM">
    <ShowAppsModal />
  </TemplateModalApps>
</template>
