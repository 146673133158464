<template>
  <div class="admin-search">
    <div class="form">
      <div class="overlay" @click="leaveSearch"></div>
      <input type="search" name="search" placeholder="Buscar" @keyup="autoSearch" @mouseup="enterSearch" autocomplete="off" />
      <div class="admin-search-result results" v-if="search && search.length" @scroll="scrolling">
        <div class="item" v-for="(model, midx) in search" :key="midx" @click="clickResult(model)">
          <div>{{ model.name }}</div>
          <div dcolor>
            <small>{{ locale.words[model.adminStatus] }}</small>
          </div>
        </div>
        <div class="admin-search-paginate">
          <div v-if="hasMore"><iconic name="gspinner" /></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import scroller from "../../assets/js/scroller";
export default {
  props: ["locale", "leaveSearch", "autoSearch", "enterSearch", "search", "searching", "searchTotal", "searchPage"],
  data: function () {
    return {
      endBuffer: false,
    };
  },
  methods: {
    clickResult: function (model) {
      this.$emit("clickResult", model);
    },
    scrolling: function ($event) {
      scroller.isBottomScroll($event, async () => {
        if (!this.endBuffer && this.hasMore) {
          this.endBuffer = true;
          await this.autoSearch({ page: this.searchPage + 1 });
          this.endBuffer = false;
        }
      });
    },
  },
  computed: {
    hasMore: function () {
      return this.searchTotal > this.search.length;
    },
  },
};
</script>

<style lang="scss" scoped>
.admin-search {
  &-paginate {
    position: relative;
    color: $primary_color;
    padding: $mpadding 0 0 0;
    text-align: center;
  }
}
</style>
