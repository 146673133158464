<script>
  export default {
    props: ["isVisible"],
    methods: {
      closeModal() {
        this.$emit("closeM");
      },
    },
  };
</script>

<template>
  <div :class="['modalCard', isVisible && 'modalCard--show']">
    <div class="modalCard__overlay" @click="closeModal" v-if="isVisible" />
    <slot></slot>
  </div>
</template>

<style lang="scss">
  .modalCard {
    position: fixed;
    bottom: -100%;
    opacity: 0;
    right: 0;
    left: 0;
    z-index: 1000;
    transition: bottom 0.4s ease-in-out, opacity 0.4s ease-in-out;
    &--show {
      bottom: 0;
      opacity: 1;
    }
    &__overlay {
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: -1;
      background: transparent;
    }
    @media (min-width: 768px) {
      right: 5px;
      top: -100%;
      opacity: 0;
      left: auto;
      bottom: auto;
      transition: top 0.4s ease-in-out, opacity 0.6s ease-in-out;
      &--show {
        top: 70px;
        bottom: auto;
        opacity: 1;
      }
    }
  }
</style>
