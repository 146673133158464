export default {
  watchScrolling: function() {
    window.removeEventListener("scroll", this.isScrolling);
    window.addEventListener("scroll", this.isScrolling);
  },
  isScrolling: function({ srcElement }) {
    const offsetGap = 64;
    const { scrollTop, offsetHeight, scrollHeight } = srcElement.documentElement;
    const scrolledToBottom = scrollTop + offsetHeight + offsetGap >= scrollHeight;
    if (scrolledToBottom) {
      let elems = document.querySelectorAll(".click-on-bottom");
      [].forEach.call(elems, function(el) {
        if (el) el.click();
      });
    }
  },
  isBottomScroll: function({ srcElement, offsetGap = 0 }, callback) {
    const { scrollTop, offsetHeight, scrollHeight } = srcElement;
    const scrolledToBottom = scrollTop + offsetHeight + offsetGap >= scrollHeight;
    if (scrolledToBottom) {
      if (callback) callback(srcElement);
    }
  },
  isTopScroll: function({ srcElement, offsetGap = 0 }, callback) {
    const { scrollTop } = srcElement;
    if (scrollTop <= offsetGap) {
      if (callback) callback(srcElement);
    }
  },
};
